
//About Information
export const AboutHeading = "A little about our App";
export const AboutBody = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';


//Pricing Information
export const priceData = [
    {
        heading: "Premium",
        body: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        className: "PricingContainer",
        
    },
    {
        heading: "Free",
        body: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        className: "PricingContainerFree"

    },
    {
        heading: "Corperate",
        body: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        className: "PricingContainer"

    }
]
//Features Information
export const featureData = [
    {
        heading: "Feature 1",
        body: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        imgUrl: "../../assets/img/iphoneTimbnk.png",
        className: "leftSideFeature",
    },
    {
        heading: "Feature 2",
        body: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        imgUrl: "../../assets/img/Ipad.png",
        className: "middleFeature",
    },
    {
        heading: "Feature 3",
        body: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        imgUrl: "../../assets/img/timebnkLaptop.png",
        className: "rightSideFeature",
    },
];

//CTA information
export const Heading = "Seen enough of our good stuff?";
export const SubHeading = "Buy now below!";


//Buy button information
export const BuyNow = 'Buy Now!';


