import React from 'react';
import './polygon.css';
import PolygonSvg from '../../assets/img/Polygon.svg'

export default function Polygon(props) {
    return( 
        <div>
            <img src={PolygonSvg} alt="" className={`${props.className} polygon`} />
        </div>
    )
}
