import React from 'react';
import '../Pricing/pricing.css';
import Button from '../etc/Button'
import Briefcase from '../../assets/img/briefcase.svg'


export function PricingBox1(props) {
    return(
        <div>
            <div className={`${props.className}`}>
                <h1 className="priceHeading">{props.heading}</h1>
                <p className="pricebody">{props.body}</p>
                <Button />
            </div>
        </div>
    )
}


