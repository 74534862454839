import React from "react";
import Hero from "./Components/Hero/Hero.jsx";
import Navbar from "./Components/Navbar/navbar.jsx";
import About from "./Components/About/About.jsx";
import Features from "./Components/Features/Features.jsx";
import Pricing from "./Components/Pricing/Pricing.jsx";
import Cta from "./Components/CTA/Cta.jsx"
//Font source can be found here
import './style.css';


//import { useState } from 'react'


function App() {
  return (
    <>
      <Navbar/>
      <Hero />
      <About />
      <Features />
      <Pricing />
      <Cta />
    </>
  );
}

export default App;
