import React from 'react';
import './about.css';
import Divider from '../../assets/img/divider.png';


export default function AboutInfo(props) {
    return(
        <div className='aboutContainer'>
            <div className="aboutInfoHeading">
                <h1 className="Heading">{props.aboutHeading}</h1>
            </div>
            <div className="seperator">
                <img src={Divider} alt="" />
            </div>
            <div className="bodyText">
                <p>{props.aboutBody}</p>
            </div>
        </div>
    ) 
}
