import "./navbar.css"

export default function MenuItems() {
    return (
        <ul className="ul">
        <li className="li">
            <a href="#">Home</a>
        </li>
        <li className="li">
            <a href="#">What Is Timebnk</a>
        </li>
        <li className="li">
            <a href="#">Features</a>
        </li>
        <li className="li">
            <a href="#">Pricing</a>
        </li>
    </ul>
    )
}
