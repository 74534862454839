import React from 'react';


export default function FeaturesBox(props) {
    return(
        <div className='box'>
            <div className={props.className}>
                <h1 className='featHeading'>{props.heading}</h1>
                <p className='featBody'>{props.body}</p>
            </div>
        </div>
    )
}
