import React from 'react';
import FeaturesBox from './FeaturesBox.jsx';
import './features.css';
import Iphone from '../../assets/img/iphoneTimbnk.png';
import Ipad from '../../assets/img/Ipad.png';
import Laptop from '../../assets/img/timebnkLaptop.png'
import { featureData } from "../../Utils/utils.jsx";

const imgs = [Iphone, Ipad, Laptop];

export default function Features(props) {
    return (
        <div className="featuresSection">
            {featureData.map(({ heading, body, className }, index) => (
                <div className={className} key={heading}>
                    <FeaturesBox heading={heading} body={body} />
                    <img className="images" src={imgs[index]} alt="" />
                </div>
            ))}
        </div>
    );
}
