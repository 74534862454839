import React from 'react'
import './cta.css'

export default function CtaInfo(props) {
  return (
    <div>
        <h1 className="ctaHeading">
            {props.heading}
        </h1>
        <h1 className='ctaHeading'>{props.subHeading}</h1>
    </div>
  )
}

