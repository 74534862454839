import { BuyNow } from '../../Utils/utils'
import React from 'react'
import './button.css'

export default function button() {
  return (
    <div className="ctaBtnContainer">
        <div className="ctaBtn">
            <h3 className="btnHeading">Buy Now</h3>
        </div>
    </div>
  )
}
