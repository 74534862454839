import React from 'react';
import MenuItems from '../menuItems.jsx'
import '../navbar.css';
import {GiHamburgerMenu} from 'react-icons/gi'


export default function Navigation() {
    function openNav() {
        console.log('Hello!');
    }
    return (
        <div className='mobileNavigation'>
            <GiHamburgerMenu className="hamburgerMenu" onClick={openNav}/>
            <div className="menu"><MenuItems /></div>
        </div>
    )
}
