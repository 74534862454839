import React from 'react';
import { PricingBox1, PricingBox2, PricingBox3 } from './PricingBox.jsx';
import './pricing.css'
import Briefcase from '../../assets/img/briefcase.svg'
import { priceData } from '../../Utils/utils.jsx';


export default function Pricing(props) {
    return( 
    <div>
        <div className="PricingSection">
            {priceData.map(({ heading, body, className }) => (
                <div className={"className"} key={heading}>
                <PricingBox1 heading={heading} body={body} className={className} />
                </div>
            ))}
        </div>
    </div>
);
}
