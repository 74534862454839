import React from 'react';
import './hero.css';
import logo1 from '../../assets/img/logo1.png';
import { BuyNow } from '../../Utils/utils'


function Hero() {
    return (
        <div className='mainSection'>
            <div className='mainContainer'>
                <div className="logoContainer">
                    <img className='logo1' src={logo1} alt="logo1" />
                </div>
                <div className="headingContainer">
                    <h1 className="heading">TimeBnk</h1>
                    <h3 className="subHeading">Lorem ipsum dolor sit amet</h3>
                    <hr className="separator" />
                    <button className='ctaHeroBtn'>{BuyNow}</button>
                </div>
            </div>
        </div>
    )
}

export default Hero;
