import React from 'react'
import { BuyNow, Heading, SubHeading } from '../../Utils/utils'
import CtaInfo from './CtaInfo.jsx'

import './cta.css'

export default function Cta(props) {
  return (
    <div>
        <div className="section">
            <CtaInfo heading={Heading} subHeading={SubHeading} />
            <button className='ctaSecBtn'><a href="/">{BuyNow}</a></button>
        </div>
    </div>
  )
}
