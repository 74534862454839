import './navbar.css';
//import Logo1 from '../../assets/img/logo1.png'
import Navigation from './navigation/Navigation';
import MobNavigation from './mobNavigation/MobNavigation.jsx'

export default function Navbar() {
    return (
    <div>
        <div className="nav">
            <Navigation />
            <MobNavigation />
        </div>
    </div>
    );
}
