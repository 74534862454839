import React from 'react';
import './about.css';
import AboutInfo from './AboutInfo';
import LaptopImg from '../../assets/img/timebnkLaptop.png';
import Polygon from '../Polygons/Polygon'
import { AboutHeading, AboutBody } from '../../Utils/utils';
import Iphone from '../../assets/img/iphoneTimbnk.png'


export default function About(props) {
    return(
            <div className="containerAbout">
                <div className="leftSide">
                    <div className="componentCard">
                        <Polygon className={"shape"} />
                        <AboutInfo aboutBody={AboutBody} aboutHeading={AboutHeading} className='aboutContainer' />
                        <Polygon className={"shape2"}/>
                    </div>
                </div>
                <div className="rightSide ">
                    <img src={LaptopImg} className="laptopImg" />
                </div>
            </div>
    )
}
