import React from 'react';
import MenuItems from '../menuItems.jsx'
import '../navbar.css';


export default function Navigation() {
    return (
        <div className='navigation'>
            <div className="menu"><MenuItems /></div>
        </div>
    )
}
